@tailwind base;
@tailwind components;
@tailwind utilities;

.main-bg {
  /* background: rgb(2, 0, 36);
  background: linear-gradient(
    45deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(24, 16, 31, 1) 47%,
    rgba(55, 41, 92, 1) 100%
  ); */
  /* background: rgb(0, 0, 0);
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(16, 46, 25, 1) 47%,
    rgba(48, 124, 41, 1) 100%
  ); */

  background: rgb(24, 61, 29);
  background: linear-gradient(
    230deg,
    rgba(24, 61, 29, 1) 0%,
    rgba(18, 46, 22, 1) 27%,
    rgba(9, 23, 11, 1) 55%,
    rgba(0, 0, 0, 1) 89%
  );
}

body {
  background-color: rgba(9, 23, 11, 1);
}

.other-main-bg {
  background-color: rgb(4, 22, 6);
}
.static-main {
  background-color: rgb(0, 0, 0);
  z-index: 99;
}

.main-color {
  color: rgba(48, 124, 41, 1);
}

.logo {
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%);
}

.text-color {
  color: #cccccc;
}

.ball {
  animation: move5 2s infinite linear;
  -webkit-animation: move5 2s infinite linear;
}
@keyframes move5 {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.radial-bg {
  /* background: rgb(0,0,0);
background: linear-gradient(360deg, rgba(0,0,0,1) 11%, rgba(35,91,30,1) 31%, rgba(45,116,38,1) 45%, rgba(48,124,41,1) 55%, rgba(34,88,29,1) 64%, rgba(0,0,0,1) 83%); */

  background: rgb(0, 0, 0);
  background: linear-gradient(
    360deg,
    rgba(0, 0, 0, 1) 11%,
    rgba(35, 91, 30, 1) 25%,
    rgba(35, 90, 30, 1) 41%,
    rgba(35, 87, 30, 1) 55%,
    rgba(34, 88, 29, 1) 64%,
    rgba(0, 0, 0, 1) 83%
  );
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
